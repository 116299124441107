<template>
    <ui-create
        name="docTypes"
        :title="title"
        :load="load"
    >
        <template #body >

                <v-form ref="form" >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Descrição"
                                v-model="form.descricao"
                                counter
                                maxlength="64"
                                :rules="[
                                    form_descricao_rules.required, form_descricao_rules.counter,
                                        form_descricao_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Observação"
                                v-model="form.observacao"
                                counter
                                maxlength="128"
                                :rules="[
                                    form_observacao_rules.required, form_observacao_rules.counter,
                                        form_observacao_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwdocTypesCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                observacao: ''
            },
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            alertSuccess: {
                show: false,
                type: 'indigo',
                text: ''
            },

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },
            form_observacao_rules: {
                required: value => !!value || 'Observação é obrigatória.',
                counter: value => value.length <= 128 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Tipo de Documento: ${this.id}` : 'Cadastrar Tipo de Documento'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            if(this.$refs.form.validate()){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/doc-types/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.clearForm()
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar o tipo de documento!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        create: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/doc-types/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Tipo de Documento cadastrado com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar o tipo de documento!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        getData: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/doc-types/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.overlay = false
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.$root.$on('save-changes-on-docTypes', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>